import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, ExemptionDto, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-exemption-item-form',
  templateUrl: './exemption-item-form.component.html',
  styleUrls: ['./exemption-item-form.component.scss'],
})
export class ExemptionItemFormComponent extends BaseForm<ExemptionDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService, ModuleKeywords.Exemption);
  }

  ngOnInit(): void { }

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      authorityDocument: new FormControl(null, Validators.required),
      citations: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
