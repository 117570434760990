<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Enter Owner'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
        [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Enter Approver'"
        [principleMode]="true"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-target-code-selector
        [label]="moduleFieldString + '.authorityDocument.label' | translate: 'Authority Document'"
        [placeholder]="moduleFieldString + '.authorityDocument.placeholder' | translate: 'Enter Authority Document'"
        [control]="formGroup?.controls?.authorityDocument"
        [viewMode]="fieldViewMode"
        [multi]="false"
        [targetTypes]="['AUTHORITY_DOCUMENT']"
      >
      </app-target-code-selector>
    </div>
    @if (formGroup?.controls?.authorityDocument?.value) {
      <div class="col-12 md:col-6">
        <app-target-code-selector
          [label]="moduleFieldString + '.citations.label' | translate: 'Citations'"
          [placeholder]="moduleFieldString + '.citations.placeholder' | translate: 'Enter Citations'"
          [control]="formGroup?.controls?.citations"
          [viewMode]="fieldViewMode"
          [multi]="true"
          [extraFilters]="
            formGroup?.controls?.authorityDocument?.value || data?.authorityDocument
              ? [
                  {
                    property: 'authorityDocument',
                    operation: 'EQUAL',
                    value: formGroup?.controls?.authorityDocument?.value || data?.authorityDocument,
                  },
                ]
              : []
          "
          [targetTypes]="['CITATION']"
        >
        </app-target-code-selector>
      </div>
    }
    <div class="col-12 md:col-12">
      <app-text-editor
        [name]="'description'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Description'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <div class="flex flex-row-reverse justify-content-between align-items-center">
    <div class="flex flex-row-reverse gap-2">
      @if (showSaveButton) {
        <app-button [action]="submitButtonAction"></app-button>
      }
      @if (showSaveButton) {
        <app-button [action]="submitSaveButtonAction"></app-button>
      }
      @if (showInPlaceButton) {
        <app-button [action]="submitInPlaceButtonAction"></app-button>
      }
      @if (fieldViewMode == 'create') {
        <app-button [action]="cancelButtonAction"></app-button>
      }
    </div>
    @if (fieldViewMode == 'create') {
      <app-switch-input
        [label]="'general.messages.create another record' | translate"
        [name]="'inPlaceCreate'"
        [labelWidthFit]="true"
        [control]="inPlaceCreateControl"
        [viewMode]="'create'"
        [styleClass]="'mb-0'"
      >
      </app-switch-input>
    }
  </div>
</form>
